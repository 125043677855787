.MuiDataGrid-columnHeaders {
  background: #ece9ff;
  & > div[role="row"] {
    background: transparent !important;
  }
}

.MuiDataGrid-virtualScroller {
  min-height: 50px;
}

.MuiDialogTitle-root {
  border-bottom: 1px solid #eee;
}

.MuiDataGrid-overlayWrapper {
  min-height: 30px !important;
}

.MuiDataGrid-columnHeaders {
  @include respond-down(S) {
    border-radius: 0 !important;
  }
  .MuiDataGrid-columnHeader {
    outline: none !important;
  }
  .MuiDataGrid-columnHeaderTitle {
    font-weight: bold;
  }
}
.row-actions {
  .MuiButtonBase-root {
    min-width: 0px !important;
    padding: 5px 8px !important;
    svg {
      width: 14px;
    }
  }
}

$succes: #aaa;
$process: #00cc31;
$error: #ff4646;

.MuiDataGrid-row {
  .dataset-name {
    display: flex;
    align-items: center;
  }
  .boll {
    width: 16px;
    height: 16px;
    display: inline-block;
    border-radius: 16px;
    margin-right: 8px;

    &.result-process {
      background: rgba($process, 1);
    }
    &.result-error {
      background: rgba($error, 1);
    }
    &.result-succes {
      background: rgba($succes, 1);
    }
  }
}

.acmg-type-pathogenic {
  color: #900;
}
.acmg-type-likely-pathogenic {
  color: rgb(153, 102, 0);
}
.acmg-type-VUS {
  color: #555;
}
.acmg-type-likely-benign {
  color: #8da300;
}
.acmg-type-benign {
  color: #00aa28;
}
