// CUSTOMS
.table-diff-UP { background: #ffb2b2; }
.table-diff-DOWN { background: hsl(222, 100%, 85%); }

.table-nowrap {
  overflow: auto;
  .table {
    white-space: nowrap;
  }
}

.data-table {
  .table-panel {
    padding: 0.4rem 1rem;
    background: #eee;
    position: relative;
    z-index: 10;

    .form-control.search {
      margin-right: 1rem;
      max-width: 300px;
    }
    .input-group {
      flex-wrap: nowrap;
      white-space: nowrap;
      margin-right: 1rem;
    }
    .table-filters {
      max-width: 400px;
      margin-right: 1rem;
    }
  }
  .table-content {
    position: relative;
    z-index: 20;
    overflow: scroll;
    padding-top: 28px;
    //margin-top: -28px;
    background: #eee;

    .table {
      background: #FFF;
    }

    tr { white-space: nowrap}

    td {
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;

      &.cls-Description {
        max-width: 500px;
      }
      &.ignore {
        opacity: 0.3;
      }
    }

    th {
      padding: 0;

      .th-wrapper {
        display: flex;
        position: relative;

        .default {
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          z-index: 10;
          padding-right: 35px;
        }

        .search-form {
          min-height: 28px;
          padding: 0.15rem 0.5rem;
          border-radius: 0;
          box-shadow: none !important;
          transition: top 0.15s ease-in-out,
            opacity 0.15s ease-in-out;
          top: 0px;
          position: absolute;
          right: 0;
          opacity: 0;
          z-index: 5;
          min-width: 160px;
        }

        .search-icon, .in-report-count {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          flex: 0 0 28px;
          z-index: 30;
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &:hover {
            background: #eee;
          }

          svg {
            position: absolute;
            transition:
                    opacity 0.15s ease-in-out,
                    transform 0.15s ease-in-out;
            &.s {
              opacity: 1;
              transform: scale(1);
            }
            &.x {
              path {
                fill: #9e0202;
              }
              opacity: 0;
              transform: scale(0.7);
            }
          }
        }

        .in-report-count {
          width: auto;
          padding: 0 3px;
          font-size: 14px;
          color: #060;
        }

        &.search-active {
          .search-form {
            top: -28px;
            opacity: 1;
          }
          .search-icon {
            svg {
              &.x {
                opacity: 1;
                transform: scale(1);
              }
              &.s {
                opacity: 0;
                transform: scale(0.7);
              }
            }
          }
        }
      }

      .search-filter {
        .search-column {
          display: none;
          &.visible {
            display: block;
          }
        }
      }

      .default {
        flex: 1 1 100%;
        padding: 2px;
        display: block;
        user-select: none;
      }

      .sortable {
        cursor: pointer;
        transition: background 0.15s ease;

        &:hover {
          background: var(--bs-table-hover-bg);
        }

        &.sort_, &.sort_0 {
          svg.s1 { fill: #999 }
          svg.s2 { display: none; }
        }
        &.sort_1 {
          svg.s1 { fill: #1000ff }
          svg.s2 { display: none; }
        }
        &.sort_2 {
          svg.s1 { display: none; }
          svg.s2 { fill: #1000ff }
        }
      }

      .search-filter {
        padding: 2px 8px;
        display: flex;
        align-items: center;
        transition: background 0.15s ease;
        cursor: pointer;

        .on { display: block }
        .off { display: none; }

        &.ignore {
          .on { display: none }
          .off { display: block; }
        }

        &:hover {
          background: var(--bs-table-hover-bg);
        }
      }
    }

    tr.active {
      cursor: pointer;
      transition: background 0.15s ease;
      &:hover {
        background: #c5ceff;
      }
    }

    .to-report {
      display: flex;
      align-items: center;

      .btn {
        padding: 3px 8px;
        margin: 1px;
        border-radius: 0;
      }
    }
  }
}