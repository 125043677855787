h2,
h4 {
  margin-bottom: 0.7em;
}

.main-nav {
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-bottom: 1em;
}

.navbar-brand {
  img {
    width: 100px;
    margin: -30px 0;
  }
}

.container-fluid {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.modal-dialog:not(.modal-fullscreen) {
  max-width: 920px;
}
.modal-dialog.modal-extra {
  max-width: 90%;
}

.modal {
  background: rgba(0, 0, 0, 0.3);
}

.boll {
  margin-bottom: -3px;
  //display: flex;
  //align-items: center;
}

.pie-title {
  padding: 12px;
  margin-top: 0;
  //text-align: center;
}

.modal-title {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 30px;
}

.modal-sample {
  .modal-dialog {
    max-width: 940px;

    .col {
      max-width: 453px;
    }
  }
}

.report-list {
  .list-group-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.max-w {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tab-content {
  border: 1px solid #ddd;
  border-top: 0;
  padding: 20px 0 0 0;
  border-radius: 0 0 4px 4px;
}

.nav-tabs .nav-link {
  background: rgba(0, 0, 0, 0.05);
}

.x-tooltip {
  text-decoration: none;
  margin: -3px 0 -3px 8px;
  padding: 3px;
  background: #60a5ff;
  width: 24px;
  height: 24px;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  color: #fff !important;
  font-size: 14px;
}

.input-group-text {
  .x-tooltip {
    margin: -3.26rem -0.36rem;
    width: 25px;
    height: 25px;
    border-radius: 4px;
    background: transparent;
    color: #60a5ff !important;
  }
}

.fix-first {
  .input-group-text:first-child {
    min-width: 80%;
  }
  .input-group-text.w-30 {
    min-width: 30%;
  }
}

.tooltip-inner {
  max-width: 390px;
  width: 390px;
}

.sources {
  padding: 0;

  .source-link {
    padding: 0.35rem 1rem;
    transition: all 0.25s ease;

    &:last-child {
      border-radius: 0 0 3px 3px;
    }

    &:hover {
      background: #444;
      color: #eee;
      cursor: pointer;
    }
  }
}

.custom-filters {
  .quick-search {
    padding-top: 0;
    width: 400px;
    margin-right: 20px;
  }
}

.quick-search {
  width: 100%;
  //margin-right: 20px;
  padding-top: 30px;
  .MuiFormControl-root {
    width: 100%;
  }
}
.MuiButtonBase-root {
  white-space: nowrap;
}

.meta-line {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-family: monospace;
  padding: 2px 4px;
}

.autocomplete-mods {
  border: 1px solid #ced4da;
  border-radius: 5px;

  .segment {
    padding: 10px;
  }
  .segment:not(:last-child) {
    border-bottom: 1px solid #ced4da;
  }

  .saved-mod {
    margin-right: 5px;
    margin-top: 5px;
  }

  .saved-mod-wrapper {
    display: inline-flex;
    align-items: center;
    position: relative;
    margin: 5px 5px 0 0;

    .del {
      border-radius: 0 4px 4px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      background: #eee;
      height: 26px;
      cursor: pointer;

      &:hover {
        transition: background-color 0.15s ease;
        background: #f00;
        color: #fff;
      }
    }
  }

  .add-item {
    cursor: pointer;
    margin-left: 5px;
    background: #eee;
    border-radius: 4px;
    padding: 1px 7px;
    white-space: nowrap;
    transition: background-color 0.15s ease;

    &.saved-mod {
      margin: 0;
      border-radius: 4px 0 0 4px;
    }

    &:hover {
      background: #222;
      color: #fff;
    }
  }
}

.buttons-mr {
  & > .btn,
  .btn-group {
    margin-right: 5px;
  }
}
.add-available {
  display: flex;
  justify-content: space-between;

  & > .btns {
    display: flex;
    justify-content: flex-end;
  }
}

.gray-group {
  padding-left: 0.55rem;
  padding-right: 0.55rem;
  background: #f1f1f1;
  border-radius: 4px;
  padding-bottom: 0.25rem;
  position: relative;

  &:hover {
    .del {
      opacity: 1;
    }
  }

  .del {
    opacity: 0;
    width: 22px;
    height: 22px;
    position: absolute;
    top: 4px;
    right: -22px;
    background: #222;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 4px 4px 0;
    color: #fff;
    cursor: pointer;
    transition:
      background 0.15s ease,
      opacity 0.15s ease;

    svg {
      width: 18px;
    }

    &:hover {
      background: #f22;
    }
  }
}

.proc-list {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 200;

  .toast {
    width: 620px;
    margin-top: 10px;

    .small {
      padding: 0 8px;
      margin: -10px 0 -10px 8px;
      font-size: 11px;
    }
  }
}

pre {
  font-size: 11px;
  background: #e8e8e8;
  color: #0b2e13;
  padding: 8px;
  border-radius: 4px;
  overflow: auto;
  white-space: pre-line;

  &.limited {
    max-height: 400px;
  }

  &.run-command {
    background: #ffebd9;
  }
}

.save-to-default {
  position: absolute;
  right: 3px;
  top: 3px;
  .btn {
    z-index: 400;
  }
}

.input-group-sm {
  .save-to-default {
    right: 1px;
    top: 1px;
    .btn {
      height: 29px;
      padding: 0 8px;
    }
  }
}
