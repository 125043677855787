.index-content {
  margin-bottom: 20px;
}
.chr-select {
  min-width: 120px;
  display: inline-block;
}
.header-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  margin-bottom: 20px;

  h2 {
    margin: 0;
    margin-right: 20px;
  }
  .custom-filters {
    display: flex;
    align-items: center;
  }
}
.header-index {
  // padding-top: 40px;
  margin-bottom: 30px;

  h1 {
    font-size: 34px;
  }

  &.center {
    text-align: center;

    p {
      font-size: 16px;
      line-height: 1.4em;
      max-width: 600px;
      margin: auto;
    }
  }
}

.modal-body {
  .header-line {
    margin-bottom: 0;
  }
}

.run-page {
  & > .MuiFormControl-root,
  & > .MuiPaper-root {
    margin-bottom: 20px;
  }
}

.filters-list {
  display: flex;
  flex-wrap: wrap;

  .filter-block {
    display: flex;
    border-radius: 5px;
    margin: 0 10px 10px 0;
    padding: 7px;
    align-items: center;
    border: 2px solid #cbc3ff;

    .MuiInputBase-root {
      margin-right: 5px;
    }
    .MuiAutocomplete-root {
      .MuiInputBase-root {
        width: 270px;
      }
    }

    .title {
      padding: 0 7px;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
  }
}

.log-files {
  min-width: 600px;
  .MuiBox-root {
    max-height: 330px;
    overflow: auto;
    font-family: monospace;
    white-space: pre-line;
    background: #f3f3f3;
    border-radius: 5px;
    margin: 16px;
    padding: 12px 16px;
    font-size: 12px;
    line-height: 1.4em;

    &.err {
      color: #900;
    }
  }
}

.results-part {
  .result-item {
    display: flex;
    margin-bottom: 10px;
    //padding: 12px;
  }
}

.settings-variables {
  h4 {
    margin-top: 0;
    margin-bottom: 15px;
  }
}
.about-page {
  max-width: 1000px;
}
