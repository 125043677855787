.MuiDialogTitle-root {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .MuiButtonBase-root {
    margin: -10px;
  }
}

.modal-footer {
  justify-content: space-between !important;
  padding: 14px 24px !important;
}

.modal-bg {
  position: absolute;
  z-index: 20;
  background: rgba(0,0,0,0.35);
}
