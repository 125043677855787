.tabs-header {
  display: flex;
  align-items: center;

  h2 {
    margin: 0 20px 0 0 ;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.tabs-modal {
  .tabs-content {
    .tab-data {
      background: #eee;
      position: relative;
      display: none;
      &.current {
        display: block;
      }
    }
  }
}