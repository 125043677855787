html,
body {
  background-color: #fdfdff;
  color: #222;
  margin: 0;
  padding: 0;
}

*::before,
*::after {
  box-sizing: inherit;
}

body {
  font:
    14px "Segoe UI",
    Helvetica,
    Arial,
    sans-serif;
  line-height: 1.35em;
}

a {
  text-decoration: none;
}

.chrx {
  padding-top: 20px;

  .chr-item {
    margin-bottom: 20px;
    border: 1px solid #999;

    .chr-title {
      background: rgba(0, 0, 0, 0.1);
      font-size: 16px;
      padding: 12px 12px;
      margin-bottom: 10px;

      span {
        font-size: 13px;
        color: #444;
        margin-left: 10px;
      }
    }
    .chr-karyo {
      padding-top: 16px;
      position: relative;
      margin: 12px;
      //max-width: 1000px;
    }
  }
}

#tooltip {
  padding: 8px 12px;
  font-size: 12px;
  max-width: 200px;
  color: #222;
  background: #fff;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  position: absolute;
  opacity: 0;

  p {
    margin: 0;
    line-height: 1.2em;
    &.gene {
      margin-bottom: 2px;
      font-weight: 700;
    }
  }
}

.karyotype-svg {
  .chr-border {
    fill: transparent;
    stroke-width: 1px;
    stroke: #111;
  }
  line.karyo-hints {
    stroke: #000;
    stroke-width: 1px;
  }
  text.karyo-hints {
    font-size: 10px;
  }
  pattern line {
    stroke: #ff0000;
    stroke-width: 0.7px;
  }
  rect.selection {
    stroke: #a00;
    fill: #f00;
    stroke-width: 1px;
    transform: translate(0, -2px);
  }
}

.report-page {
  padding: 24px;
  min-width: 400px;
}

.application {
  .nav-panel {
    padding: 0 20px;
    //display: flex;
    background: #222;
    //width: 100%;

    .MuiTab-root {
      font-weight: bold;
      color: #b5b5b5;
      &.Mui-selected {
        color: #fff;
      }
    }
    .MuiTabs-indicator {
      background: #b5b5b5;
    }
  }
  .app-content {
    padding: 20px;

    @include respond-down(S) {
      padding: 20px 0 0 0;
    }
  }

  .page {
    .header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      justify-content: space-between;

      @include respond-down(S) {
        padding: 0 20px;
      }

      h2 {
        margin: 0 20px 0 0;
      }

      .header-buttons {
        display: flex;
        align-items: center;

        .btn {
          margin-left: 8px;
        }
      }
    }
  }
}
