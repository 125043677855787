.login-form-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
.login-form {
  max-width: 350px;
  min-width: 300px;
}
