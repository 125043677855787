.file-browser {
  .browser-wrapper {
    position: relative;

    .MuiFormControl-root {
      width: 100%;
    }

    input[type="text"] {
      cursor: pointer;
      background: #fff;
    }
  }

  .is-default-value {
    position: absolute;
    right: 7px;
    top: 6px;
    height: 26px;
    align-items: center;
    display: flex;
    background: #ffe9d1;
    padding: 0 10px;
    border-radius: 2px;
  }

  .selector {
    display: none;
    padding: 0;
    z-index: 20;
    min-width: 260px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    flex-direction: column;
    user-select: none;
    margin-top: 5px;
    border-radius: 3px;

    &.active {
      display: flex;
    }

    .tree {
      overflow: auto;
      flex: 1 1 100%;
      margin-left: 10px;
      padding: 4px 0;
      max-height: 460px;

      .children {
        margin-left: 20px;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          top: -36px;
          left: -18px;
          border-left: 1px solid #999;
          width: 1px;
          bottom: 10px;
        }

        &:after {
          content: "";
          position: absolute;
          border-top: 1px solid #999;
          top: -12px;
          left: -18px;
          width: 10px;
        }
      }

      .item:last-child {
        & > .children:before {
          height: 24px;
        }
      }

      .item {
        position: relative;

        &.type-dir > .meta > .file {
          display: none;
        }
        &.type-file > .meta {
          & > .folder {
            display: none;
          }
          & > .name .arrow {
            opacity: 0;
          }
        }

        .meta {
          padding-left: 16px;
          height: 24px;
          display: flex;
          align-items: center;
          transition: background-color 0.2s ease;
          cursor: pointer;
          position: relative;
          z-index: 20;

          &:after {
            width: 10px;
            height: 1px;
            background: #888;
            position: absolute;
            left: 0;
            top: 12px;
            //content: '';
          }

          &:hover {
            .name {
              background-color: #eee;
            }
          }

          .name {
            box-sizing: border-box;
            border-radius: 4px;
            padding: 3px 6px 3px 5px;
            flex: 1 1 100%;
            margin: 0 6px 0 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition:
              background 0.2s,
              color 0.2s;
            height: 24px;
            white-space: nowrap;

            .arrow {
              transition: transform 0.25s ease;
              transform: rotate(0deg);
            }

            &.selected {
              background: #2846ff;
              color: #fff;
            }
          }

          svg {
            flex: 0 0 auto;
          }
        }

        .children > .item {
          display: none;
        }

        &.opened {
          & > .meta > .name .arrow {
            transform: rotate(180deg);
          }

          & > .children > .item {
            display: block;
          }
        }
      }
    }

    .footer {
      border-top: 1px solid #eee;
      flex: 0 0 auto;
      padding: 12px;
      display: flex;
      justify-content: space-between;

      .cnt {
        span {
          min-width: 20px;
          height: 20px;
          font-size: 12px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #fff;
          margin: -4px -10px -4px 6px;
          color: #444;
          border-radius: 20px;
        }
      }
    }
  }
}
