.custom-table {

  .handle {
    cursor: move;
    border: 1px solid #d9d9d9;
    height: 30px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -1px;
  }

  .custom-body {
    margin-bottom: 20px;

    .row-wrapper {
      align-items: center;
      margin-bottom: 10px;
      border-radius: 5px;

      .variants {
        background: #fff;
        margin: -1px 0 0 39px;
        border: 1px solid #d9d9d9;
        border-radius: 0 0 5px 5px;

        .current {
          padding: 3px;

          .empty {
            display: block;
            padding: 7px 5px;
            color: rgba(#5f2120, 0.5);
          }

          .one {
            margin: 3px;
          }
        }
        .add-option {
          border-top: 1px solid #d9d9d9;
          display: flex;
          align-items: center;

          input {
            flex: 1 1 100%;
          }
          svg {
            width: 20px;
            color: #1976d2
          }
        }
      }

      .row-panel {
        display: flex;
        min-width: 680px;

        .handle {
          border-radius: 5px 0 0 5px;
          width: 40px;
          background: #eee;
          height: auto;
        }

        .panel {
          background: #fff;
          flex: 1 1 100%;
          border-radius: 0 5px 5px 0;
          border: 1px solid #d9d9d9;
          padding: 8px 14px;
          display: flex;
          align-items: center;

          &.panel-select {
            border-radius: 0 5px 0 0;
          }

          svg {
            width: 26px;
            fill: #777
          }

          .input-wrapper {
            flex: 1 1 100%;
            margin: 0 12px;
          }
          .preview-wrapper {
            height: 32px;
          }
        }
      }


      &:hover {
        .remove {
          // opacity: 1;
        }
      }
    }
  }
}

.new-col {
  button {
    svg {
      width: 22px;
      //margin-right: 5px;
      //margin-top: -2px;
    }
    span {
      text-transform: capitalize;
      font-size: 0.9rem;
      padding-top: 5px;
    }
  }
}
