.autocomplete-wrapper {
  position: relative;

  .search-wrapper {
    position: relative;

    .item {
      background: #d1e7dd;
      position: absolute;
      top: 3px;
      left: 3px;
      right: 3px;
      bottom: 3px;
      margin: 0;
      display: flex;
      cursor: default;
      align-items: center;

      .delete {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 40px;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: color 0.15s ease;

        &:hover {
          color: #F00;
        }
      }
    }
  }

  .item {
    padding: 0.1rem 0.75rem;
    background: #ebf6f3;
    border-radius: 4px;
    margin-top: 3px;
    cursor: pointer;
    transition: background 0.15s ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.dis {
      cursor: default;
      background: #eee !important;
    }

    &:first-child {
      margin-top: 8px;
    }

    &:hover {
      background: #d1e7dd;
    }

    .path {
      font-size: 80%;
      overflow: hidden;
      text-overflow: ellipsis;

      &.mline {
        white-space: normal;
      }
    }
  }
}

.autocomplete-mods {
  .autocomplete-wrapper {
    flex: 2 2 30%;
    //margin-right: 9px;
  }

  .f-term {
    flex: 0 0 170px;
    border-bottom-left-radius: 3px !important;
    border-top-left-radius: 3px !important;
  }
  .f-amino {
    flex: 0 0 120px;
    border-bottom-right-radius: 3px !important;
    border-top-right-radius: 3px !important;
  }

  .search-abs-items {
    position: absolute;
    top: 100%;
    z-index: 50;
    left: 8px;
    right: 8px;
    border-radius: 0 0 4px 4px !important;
    overflow: hidden;
    //box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    margin-top: -1px;
    border: 1px solid #86b7fe;

    .item {
      margin: 0 !important;
      border-radius: 0;
      &:not(:last-child) {
        border-bottom: 1px solid rgba(0,0,0,0.07);
      }
    }
  }

  .gray-group {
    padding: 0.55rem;
  }

}